<template>
  <div class="dashboard-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img
              src="@/assets/optionsTechnology-logo.svg"
              alt="Options IT Logo"
            />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>

    <div class="main-content">
      <header class="content-header">
        <h1>Options - Aegis</h1>
        <div class="dropdown">
          <label for="client-select">
            Select Client to begin Comparison Checks:
          </label>
          <select id="client-select" v-model="selectedClient">
            <option v-for="client in clients" :key="client" :value="client">
              {{ client }}
            </option>
          </select>
          <button @click="fetchClientConfig">Fetch Configuration</button>
          <button
            @click="compareFiles"
            v-if="
              Object.keys(baselineConfig).length &&
              Object.keys(clientConfig).length
            "
          >
            Compare to Options Baseline
          </button>

          <button
            @click="compareStandardBaseline"
            v-if="Object.keys(clientConfig).length"
            class="btn-compare-standard"
          >
            Compare to Standard Baseline
          </button>
        </div>
      </header>

      <div class="content">
        <LoadingBar
          :loading="loading"
          :progressPercentage="progressPercentage"
        />
        <div v-if="errorMessage" class="error">{{ errorMessage }}</div>

        <!-- Display Fetched Configurations -->
        <div
          v-if="
            showConfigurations &&
            Object.keys(baselineConfig).length &&
            Object.keys(clientConfig).length
          "
          class="config-container"
        >
          <div class="config">
            <h2>Baseline Configuration</h2>
            <pre>{{ formattedBaselineConfig }}</pre>
          </div>
          <div class="config">
            <h2>Client Configuration</h2>
            <pre>{{ formattedClientConfig }}</pre>
          </div>
        </div>

        <!-- Total Passes and Total Fails Summary Tiles -->
        <div
          v-if="!showConfigurations && Object.keys(filteredResults).length > 0"
          class="comparison-results"
        >
          <div class="comparison-header">
            <h2>Detailed Comparison Results</h2>
            <div class="toggle-container">
              <SwitchToggle
                v-model:checked="showAllResults"
                label="Show All Results"
              />
              <span class="toggle-label">{{ switchLabel }}</span>
              <!-- Export Report Button shown only when comparisonCompleted is true -->
              <button
                v-if="comparisonCompleted"
                @click="openExportModal"
                class="export-button"
              >
                Export Report
              </button>
            </div>
          </div>
          <StatusTiles :totalPasses="totalPasses" :totalFails="totalFails" />
        </div>

        <!-- Stats Tiles Summary -->
        <div v-if="Object.keys(filteredResults).length > 0" class="stats-tiles">
          <StatsTile
            v-for="(categoryResults, categoryName) in filteredResults"
            :key="categoryName"
            :areaTitle="categoryName"
            :policyCounts="{
              totalPasses: categoryResults.totalPasses,
              totalFails: categoryResults.totalFails,
            }"
            :passingPolicies="categoryResults.passingPolicies"
            :failingPolicies="categoryResults.failingPolicies"
            @more-info="openPolicyModal"
          />
        </div>

        <div v-if="standardBaselineActive" class="baseline-note">
          <div class="info-banner">
            <i class="info-icon">ℹ️</i>
            <p>
              Currently showing comparison against standard security baseline.
              These are security best practices that should be present in all
              Microsoft 365 tenants.
            </p>
          </div>
        </div>

        <!-- Detailed Comparison Table -->
        <div
          v-if="!showConfigurations && Object.keys(filteredResults).length > 0"
          class="comparison-results"
        >
          <div
            v-for="(categoryResults, categoryName) in filteredResults"
            :key="categoryName"
            class="category-section"
          >
            <h3>{{ categoryName }}</h3>
            <div
              v-for="(policyResults, policyName) in categoryResults.policies"
              :key="policyName"
              class="policy-section"
            >
              <h4>{{ policyName }}</h4>
              <table>
                <thead>
                  <tr>
                    <th>Setting</th>
                    <th>Desired State</th>
                    <th>Client Tenant</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="result in policyResults"
                    :key="result.Path"
                    :class="{ pass: result.Equal, fail: !result.Equal }"
                  >
                    <td>{{ result.Path }}</td>
                    <td>{{ result.RefValue }}</td>
                    <td>{{ result.DiffValue }}</td>
                    <td
                      :class="{
                        'status-pass': result.Equal,
                        'status-fail': !result.Equal,
                      }"
                    >
                      {{ result.Equal ? "Pass" : "Fail" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Policy Modal for Detailed Viewing -->
      <PolicyModal
        v-if="isPolicyModalOpen"
        :show="isPolicyModalOpen"
        :areaTitle="selectedAreaTitle"
        :passingPolicies="selectedPassingPolicies"
        :failingPolicies="selectedFailingPolicies"
        @close="closePolicyModal"
      />

      <!-- Export Report Modal -->
      <ExportReportModal
        v-if="isExportModalOpen"
        :show="isExportModalOpen"
        @close="closeExportModal"
        @export="handleExportReport"
      />
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/components/NavBar.vue";
import LoadingBar from "@/views/components/LoadingBar.vue";
import { msalInstance } from "@/auth.js";
import {
  fetchClients,
  fetchAllJsonFilesv3,
  fetchJsonFile,
  fetchAndCompareAllFilesv6,
} from "@/azure-devops-api.js";
import { mapState } from "vuex";
import SwitchToggle from "@/views/components/SwitchToggle.vue";
import StatusTiles from "@/views/components/StatusTiles.vue";
import StatsTile from "@/views/components/StatsTile.vue";
import PolicyModal from "@/views/components/PolicyModal.vue";
import ExportReportModal from "@/views/components/ExportReportModal.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { 
  conditionalAccessChecks, 
  azureADDirectorySettingsChecks,
  deviceRegistrationPolicyChecks,
  defenderOffice365Checks,
  exchangeSecurityChecks 
} from "@/standard-security-baseline.js";

export default {
  components: {
    NavBar,
    LoadingBar,
    SwitchToggle,
    StatusTiles,
    StatsTile,
    PolicyModal,
    ExportReportModal,
  },
  data() {
    return {
      selectedClient: "",
      clients: [],
      baselineConfig: {},
      clientConfig: {},
      categorizedResults: {},
      showConfigurations: false,
      loading: false,
      progressPercentage: 0,
      errorMessage: "",
      showAllResults: true,
      isPolicyModalOpen: false,
      selectedAreaTitle: "",
      selectedPassingPolicies: [],
      selectedFailingPolicies: [],
      isExportModalOpen: false,
      logoFile: null,
      supportMessage: "",
      comparisonCompleted: false,
      exportAllResults: true, // New variable to track export option
      loggedInUser: null, // New property to store user details
      standardBaselineActive: false,
    };
  },
  computed: {
    ...mapState(["isLoggedIn"]),
    formattedBaselineConfig() {
      return JSON.stringify(this.baselineConfig, null, 2);
    },
    formattedClientConfig() {
      return JSON.stringify(this.clientConfig, null, 2);
    },
    filteredResults() {
      return Object.entries(this.categorizedResults).reduce(
        (acc, [category, policies]) => {
          if (category.toLowerCase().includes("descriptions")) {
            console.log(`🛑 Removing Descriptions from categorizedResults`);
            return acc; // 🚀 Skip Descriptions category
          }

          const passingPolicies = [];
          const failingPolicies = [];

          const filteredPolicies = Object.entries(policies).reduce(
            (policyAcc, [policyName, results]) => {
              const filteredResults = this.showAllResults
                ? results
                : results.filter((result) => !result.Equal);

              if (filteredResults.length > 0) {
                policyAcc[policyName] = filteredResults;

                if (filteredResults.every((result) => result.Equal)) {
                  passingPolicies.push(policyName);
                } else {
                  failingPolicies.push(policyName);
                }
              }
              return policyAcc;
            },
            {}
          );

          if (Object.keys(filteredPolicies).length > 0) {
            acc[category] = {
              totalPasses: passingPolicies.length,
              totalFails: failingPolicies.length,
              policies: filteredPolicies,
              passingPolicies,
              failingPolicies,
            };
          }
          return acc;
        },
        {}
      );
    },

    switchLabel() {
      return this.showAllResults ? "Show All Results" : "Show Only Fails";
    },
    totalPasses() {
      return Object.values(this.categorizedResults)
        .flatMap((category) => Object.values(category))
        .flat()
        .filter((result) => result.Equal).length;
    },
    totalFails() {
      return Object.values(this.filteredResults) // ✅ Uses filteredResults instead
        .flatMap((category) => Object.values(category.policies || {}))
        .flat()
        .filter((result) => !result.Equal).length;
    },
  },
  async created() {
    this.loading = true;
    try {
      const accessToken = await this.acquireToken();
      this.clients = await fetchClients(
        accessToken,
        "62b297c6-760c-4250-a926-52b8850e6d5f",
        "/Clients"
      );
    } catch (error) {
      this.errorMessage = "Error fetching clients: " + error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async acquireToken() {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
        };
        const response = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );

        // Retrieve and store the logged-in user's details
        this.loggedInUser = {
          upn: accounts[0].username,
          displayName: accounts[0].name || accounts[0].username,
        };

        return response.accessToken;
      } else {
        throw new Error("No accounts available for authentication.");
      }
    },
    async fetchClientConfig() {
      this.showConfigurations = true;
      this.comparisonResults = [];
      this.categorizedResults = {};
      this.loading = true;
      this.progressPercentage = 0;
      this.errorMessage = "";

      try {
        const accessToken = await this.acquireToken();
        const baselineDir = "/M365Config/Resources";
        const clientDir = `/Clients/${this.selectedClient}/M365Config/Resources`;

        // Progress callback for listing files
        const updateProgressListing = (progress) => {
          this.progressPercentage = progress * 0.5;
        };

        // Define excluded folders for consistency
        const excludedFolders = [
          "/M365Config/Resources/Descriptions",
          "/M365Config/Resources/Office365",
          "/M365Config/Resources/PowerPlatform",
          "/M365Config/Resources/SecurityCompliance",
        ];

        console.log("🚀 Setting Excluded Folders:", excludedFolders);

        // Fetch files using fetchAllJsonFilesv3 with the exclusions
        const [baselineFiles, clientFiles] = await Promise.all([
          fetchAllJsonFilesv3(
            accessToken,
            "62b297c6-760c-4250-a926-52b8850e6d5f",
            baselineDir,
            excludedFolders,
            updateProgressListing
          ),
          fetchAllJsonFilesv3(
            accessToken,
            "62b297c6-760c-4250-a926-52b8850e6d5f",
            clientDir,
            excludedFolders,
            updateProgressListing
          ),
        ]);

        this.baselineConfig = {};
        this.clientConfig = {};

        const totalFiles = baselineFiles.length + clientFiles.length;
        let processedFiles = 0;

        // Update progress for content fetching
        const updateProgressContent = () => {
          processedFiles += 1;
          const contentProgress = Math.floor(
            (processedFiles / totalFiles) * 50
          );
          this.progressPercentage = 50 + contentProgress;
        };

        // Process baseline files
        const baselineFilePromises = baselineFiles.map(async (file) => {
          const response = await fetchJsonFile(
            accessToken,
            "62b297c6-760c-4250-a926-52b8850e6d5f",
            file
          );
          updateProgressContent();
          try {
            return {
              relativePath: file.replace(baselineDir, ""),
              content: JSON.parse(response.content),
            };
          } catch (error) {
            console.error(`Invalid JSON in file: ${file}`, error);
            return {
              relativePath: file.replace(baselineDir, ""),
              content: null,
            };
          }
        });

        // Process client files
        const clientFilePromises = clientFiles.map(async (file) => {
          const response = await fetchJsonFile(
            accessToken,
            "62b297c6-760c-4250-a926-52b8850e6d5f",
            file
          );
          updateProgressContent();
          try {
            return {
              relativePath: file.replace(clientDir, ""),
              content: JSON.parse(response.content),
            };
          } catch (error) {
            console.error(`Invalid JSON in file: ${file}`, error);
            return {
              relativePath: file.replace(clientDir, ""),
              content: null,
            };
          }
        });

        const baselineFileContents = await Promise.all(baselineFilePromises);
        const clientFileContents = await Promise.all(clientFilePromises);

        baselineFileContents.forEach((file) => {
          this.baselineConfig[file.relativePath] = file.content;
        });
        clientFileContents.forEach((file) => {
          this.clientConfig[file.relativePath] = file.content;
        });

        this.showConfigurations = true;
      } catch (error) {
        this.errorMessage =
          "Error fetching client configuration: " + error.message;
      } finally {
        this.loading = false;
        this.progressPercentage = 0;
      }
    },

    // Add this as a new method in your methods section
    async compareStandardBaseline() {
      this.loading = true;
      this.errorMessage = "";
      this.showConfigurations = false;
      this.comparisonCompleted = false;
      this.standardBaselineActive = true;

      try {
        // Initialize results structure with categories
        this.categorizedResults = {
          "Conditional Access": {},
          "Azure AD Settings": {},
          "Device Settings": {},
          "Defender for Office 365": {},
          "Exchange Security": {},
        };

        // 1. Check Conditional Access policies (existing code)
        const conditionalAccessPathPattern =
          "/azuread/conditionalaccess/conditionalaccesspolicies/";
        const conditionalAccessPaths = Object.keys(this.clientConfig).filter(
          (path) => path.toLowerCase().includes(conditionalAccessPathPattern)
        );

        console.log(
          "Matched conditional access paths:",
          conditionalAccessPaths
        );

        // Only proceed with conditional access checks if we found any
        if (conditionalAccessPaths.length > 0) {
          // Extract the client's conditional access policies
          const clientConditionalAccessPolicies = [];

          for (const path of conditionalAccessPaths) {
            const policyContent = this.clientConfig[path];

            // If the content is directly a policy object
            if (
              policyContent &&
              typeof policyContent === "object" &&
              !Array.isArray(policyContent)
            ) {
              clientConditionalAccessPolicies.push(policyContent);
            }
            // If it's an array of policies
            else if (Array.isArray(policyContent)) {
              clientConditionalAccessPolicies.push(...policyContent);
            }
          }

          console.log(
            "Extracted conditional access policies:",
            clientConditionalAccessPolicies
          );

          if (clientConditionalAccessPolicies.length > 0) {
            // Apply each standard baseline check for conditional access
            for (const [checkId, check] of Object.entries(
              conditionalAccessChecks
            )) {
              const result = check.checkFunction(
                clientConditionalAccessPolicies
              );

              this.categorizedResults["Conditional Access"][check.title] = [
                {
                  Path: checkId,
                  RefValue: "Compliant",
                  DiffValue: result ? "Compliant" : "Non-compliant",
                  Equal: result,
                  Description: check.description,
                },
              ];
            }
          } else {
            console.log(
              "Found conditional access policy files, but could not extract policy content"
            );
          }
        } else {
          console.log("No conditional access policies found");
        }

        // 2. Check Azure AD Directory Settings
        // Collect all Azure AD directory settings files
        const directorySettingsPattern = "/azuread/azureaddirectorysetting";
        const directorySettingsPaths = Object.keys(this.clientConfig).filter(
          (path) => path.toLowerCase().includes(directorySettingsPattern)
        );

        console.log(
          "Matched directory settings paths:",
          directorySettingsPaths
        );

        if (directorySettingsPaths.length > 0) {
          // Collect all directory settings into an array
          const directorySettings = [];

          for (const path of directorySettingsPaths) {
            const settingContent = this.clientConfig[path];
            if (settingContent && typeof settingContent === "object") {
              directorySettings.push(settingContent);
            }
          }

          // Also look for Authorization Policy
          const authPolicyPattern = "/azuread/authorization policy";
          const authPolicyPaths = Object.keys(this.clientConfig).filter(
            (path) => path.toLowerCase().includes(authPolicyPattern)
          );

          if (authPolicyPaths.length > 0) {
            for (const path of authPolicyPaths) {
              const policyContent = this.clientConfig[path];
              if (policyContent && typeof policyContent === "object") {
                directorySettings.push(policyContent);
              }
            }
          }

          console.log("Extracted directory settings:", directorySettings);

          if (directorySettings.length > 0) {
            // Apply each directory settings check
            for (const [checkId, check] of Object.entries(
              azureADDirectorySettingsChecks
            )) {
              const result = check.checkFunction(directorySettings);
              this.categorizedResults["Azure AD Settings"][check.title] = [
                {
                  Path: checkId,
                  RefValue: "Compliant",
                  DiffValue: result ? "Compliant" : "Non-compliant",
                  Equal: result,
                  Description: check.description,
                },
              ];
            }
          } else {
            console.log(
              "Found directory settings paths, but could not extract settings content"
            );
          }
        } else {
          console.log("No directory settings found");
        }

        // 3. Check Device Registration Policy
        const devicePolicyPattern =
          "/azuread/devicesettings/deviceregistrationpolicy";
        const devicePolicyPaths = Object.keys(this.clientConfig).filter(
          (path) => path.toLowerCase().includes(devicePolicyPattern)
        );

        console.log(
          "Matched device registration policy paths:",
          devicePolicyPaths
        );

        if (devicePolicyPaths.length > 0) {
          const devicePolicy = this.clientConfig[devicePolicyPaths[0]];

          if (devicePolicy && typeof devicePolicy === "object") {
            console.log("Extracted device registration policy:", devicePolicy);

            // Apply each device registration policy check
            for (const [checkId, check] of Object.entries(
              deviceRegistrationPolicyChecks
            )) {
              const result = check.checkFunction(devicePolicy);
              this.categorizedResults["Device Settings"][check.title] = [
                {
                  Path: checkId,
                  RefValue: "Compliant",
                  DiffValue: result ? "Compliant" : "Non-compliant",
                  Equal: result,
                  Description: check.description,
                },
              ];
            }
          } else {
            console.log(
              "Found device policy path, but could not extract policy content"
            );
          }
        } else {
          console.log("No device registration policy found");
        }

        // 4. Check Defender for Office 365 Settings
        const defenderO365Pattern = "/defender/office_365/default";
        const defenderO365Paths = Object.keys(this.clientConfig).filter(
          (path) => path.toLowerCase().includes(defenderO365Pattern)
        );

        console.log(
          "Matched Defender for Office 365 paths:",
          defenderO365Paths
        );

        if (defenderO365Paths.length > 0) {
          const defenderPolicy = this.clientConfig[defenderO365Paths[0]];

          if (defenderPolicy && typeof defenderPolicy === "object") {
            console.log(
              "Extracted Defender for Office 365 policy:",
              defenderPolicy
            );

            // Apply each Defender for Office 365 check
            for (const [checkId, check] of Object.entries(
              defenderOffice365Checks
            )) {
              const result = check.checkFunction(defenderPolicy);
              this.categorizedResults["Defender for Office 365"][check.title] =
                [
                  {
                    Path: checkId,
                    RefValue: "Compliant",
                    DiffValue: result ? "Compliant" : "Non-compliant",
                    Equal: result,
                    Description: check.description,
                  },
                ];
            }
          } else {
            console.log(
              "Found Defender for Office 365 path, but could not extract policy content"
            );
          }
        } else {
          console.log("No Defender for Office 365 policy found");
        }

        // Add to your compareStandardBaseline method after the existing checks

        // 5. Check Exchange Security Settings
        const exchangePattern = "/exchange/";
        const exchangePaths = Object.keys(this.clientConfig).filter((path) =>
          path.toLowerCase().includes(exchangePattern)
        );

        console.log("Matched Exchange settings paths:", exchangePaths);

        if (exchangePaths.length > 0) {
          // Collect all Exchange settings into an array
          const exchangeSettings = [];

          for (const path of exchangePaths) {
            const settingContent = this.clientConfig[path];
            if (settingContent && typeof settingContent === "object") {
              exchangeSettings.push(settingContent);
            }
          }

          console.log("Extracted Exchange settings:", exchangeSettings);

          if (exchangeSettings.length > 0) {
            // Apply each Exchange security check
            for (const [checkId, check] of Object.entries(
              exchangeSecurityChecks
            )) {
              const result = check.checkFunction(exchangeSettings);
              this.categorizedResults["Exchange Security"][check.title] = [
                {
                  Path: checkId,
                  RefValue: "Compliant",
                  DiffValue: result ? "Compliant" : "Non-compliant",
                  Equal: result,
                  Description: check.description,
                },
              ];
            }
          } else {
            console.log(
              "Found Exchange settings paths, but could not extract settings content"
            );
          }
        } else {
          console.log("No Exchange settings found");
        }





        // Check if we have any results at all
        const hasResults = Object.values(this.categorizedResults).some(
          (category) => Object.keys(category).length > 0
        );

        if (!hasResults) {
          this.errorMessage =
            "No security settings found to evaluate in the client configuration.";
          this.loading = false;
          return;
        }

        this.comparisonCompleted = true;
      } catch (error) {
        this.errorMessage =
          "Error comparing to standard baseline: " + error.message;
        console.error("Standard baseline comparison error:", error);
      } finally {
        this.loading = false;
        this.progressPercentage = 0;
      }
    },

    async compareFiles() {
      this.loading = true;
      this.errorMessage = "";
      this.showConfigurations = false;
      this.comparisonCompleted = false;

      try {
        const accessToken = await this.acquireToken();
        const baselineDir = "/M365Config/Resources";
        const clientDir = `/Clients/${this.selectedClient}/M365Config/Resources`;

        const updateProgress = (progress) => {
          this.progressPercentage = progress;
        };

        // Define the same excluded folders for comparison
        const excludedFolders = [
          "/M365Config/Resources/Descriptions",
          "/M365Config/Resources/Office365",
          "/M365Config/Resources/PowerPlatform",
          "/M365Config/Resources/SecurityCompliance",
        ];

        this.categorizedResults = await fetchAndCompareAllFilesv6(
          accessToken,
          "62b297c6-760c-4250-a926-52b8850e6d5f",
          baselineDir,
          clientDir,
          [
            "DefaultMailboxRegion",
            "AllowedMailboxRegions",
            "RejectAnonymousDirectSend",
            "RPSEnabled",
            "appStoreUrl",
            "DefaultAuthenticationPolicy",
            "ReleaseTrack",
            "Identity",
            "Name",
            "InPlaceHolds",
            "deviceNameTemplate",
            "Size",
            "largeIcon.value",
            "deployedAppCount",
            "ImmutableId",
            "Priority",
            "CreatedBy",
            "LastModifiedBy",
            "Guid",
            "OrganizationId",
            "OrganizationalUnitRoot",
            "ObjectCategory",
            "DistinguishedName",
            "UnifiedAuditLogFirstOptInDate",
            "@odata.type",
            "id",
            "securityIdentifier",
            "authenticationStrength@odata.context",
            "grantControls.authenticationStrength@odata.context",
            "advancedThreatProtectionOnboardingBlob",
            "description",
            "version",
            "createdDateTime",
            "lastModifiedDateTime",
            "settings@odata.context",
            "informationUrl",
            "ExchangeObjectId",
            "GroupLifeCyclePolicy",
            "GroupsCreationWhitelistedId",
            "AdminDisplayVersion",
            "RBACConfigurationVersion",
            "properties.metadata.createdBy",
            "properties.metadata.createdOn",
            "properties.metadata.updatedBy",
            "properties.metadata.updatedOn",
            "systemData.createdBy",
            "systemData.createdAt",
            "systemData.lastModifiedBy",
            "systemData.lastModifiedAt",
            "conditions.users.excludeUsers",
            "conditions.locations.excludeLocations",
            "conditions.locations.includeLocations",
            "MicrosoftExchangeRecipientEmailAddresses",
            "MicrosoftExchangeRecipientPrimarySmtpAddress",
            "SharePointUrl",
            "LegacyExchangeDN",
            "CurrentMailboxForests",
            "isAssigned",
            "mailNickname",
            "onPremisesObjectIdentifier",
            "wellKnownObject",
            "appMetadata",
          ],
          updateProgress,
          excludedFolders
        );

        console.log("Categorized Results:", this.categorizedResults);
        this.showConfigurations = false;
        this.comparisonCompleted = true;
      } catch (error) {
        this.errorMessage = "Error comparing files: " + error.message;
      } finally {
        this.loading = false;
        this.progressPercentage = 0;
      }
    },
    exportToPDF({ supportMessage, logoFile, exportAllResults }) {
      const doc = new jsPDF();
      const pageHeight = doc.internal.pageSize.height;
      let yPosition = 20;

      const resultsToExport = exportAllResults
        ? this.categorizedResults
        : this.filteredResultsOnlyFails();

      const generatePDFContent = (logoImage = null) => {
        if (logoImage) {
          const pdfWidth = doc.internal.pageSize.getWidth();
          const logoWidth = pdfWidth * 0.95;
          const logoHeight = (320 / 1630) * logoWidth;
          doc.addImage(
            logoImage,
            "PNG",
            (pdfWidth - logoWidth) / 2,
            10,
            logoWidth,
            logoHeight
          );
          yPosition = logoHeight + 20;
        }

        // Add the title with baseline type indication
        const baselineType = this.standardBaselineActive
          ? "Standard Security Baseline"
          : "Options IT Baseline";
        const reportTitle = `${this.selectedClient} - ${baselineType} - Reconcile Report`;
        doc.setFontSize(20);
        doc.text(reportTitle, 10, yPosition);
        yPosition += 20;

        // Add date and user information
        const currentDate = new Date().toLocaleDateString();
        const generatedBy = this.loggedInUser
          ? `Generated on: ${currentDate} by ${
              this.loggedInUser.displayName || this.loggedInUser.upn
            }`
          : `Generated on: ${currentDate}`;
        doc.setFontSize(10);
        doc.text(generatedBy, 10, yPosition);
        yPosition += 20;

        // For standard baseline, add a brief explanation
        if (this.standardBaselineActive) {
          const baselineExplanation =
            "This report compares the tenant configuration against standard Microsoft 365 security best practices that apply to all organizations.";
          doc.setFontSize(10);
          doc.text(baselineExplanation, 10, yPosition, { maxWidth: 180 });
          yPosition += 15;
        }

        // Generate the main content starting from the updated yPosition
        this.generatePDFContent(doc, yPosition, pageHeight, resultsToExport);
      };

      // Call the generatePDFContent function with the logo if available
      if (logoFile) {
        generatePDFContent(logoFile);
      } else {
        generatePDFContent();
      }

      // Add support message to a new page if provided
      if (supportMessage) {
        doc.addPage();
        yPosition = 20;
        doc.setFontSize(12);
        doc.text("Support / Contact Information", 10, yPosition);
        yPosition += 10;
        doc.setFontSize(10);
        doc.text(supportMessage, 10, yPosition, { maxWidth: 180 });
      }

      // Save the PDF with a filename including the client code and baseline type
      const baselineTypeShort = this.standardBaselineActive
        ? "Standard"
        : "Options";
      const fileName = `${this.selectedClient} - ${baselineTypeShort} Baseline Reconcile Report.pdf`;
      doc.save(fileName);
    },
    generatePDFContent(doc, startY, pageHeight, resultsToExport) {
      let yPosition = startY;

      Object.entries(resultsToExport).forEach(([category, policies]) => {
        if (yPosition + 10 > pageHeight) {
          doc.addPage();
          yPosition = 20;
        }

        doc.setFontSize(12);
        doc.text(category, 10, yPosition);
        yPosition += 10;

        Object.entries(policies).forEach(([policyName, results]) => {
          if (yPosition + 20 > pageHeight) {
            doc.addPage();
            yPosition = 20;
          }

          doc.setFontSize(10);
          doc.text(policyName, 10, yPosition);
          yPosition += 10;

          const rows = results.map((result) => [
            result.Path,
            result.RefValue,
            result.DiffValue,
            result.Equal ? "Pass" : "Fail",
          ]);

          doc.autoTable({
            head: [["Setting", "Desired State", "Client Tenant", "Status"]],
            body: rows,
            startY: yPosition,
            theme: "grid",
            headStyles: {
              fillColor: [224, 224, 224],
              textColor: [51, 51, 51],
              fontSize: 10,
              halign: "left",
            },
            bodyStyles: {
              textColor: [51, 51, 51],
              cellPadding: 5,
              fontSize: 9,
              overflow: "linebreak",
            },
            alternateRowStyles: {
              fillColor: [249, 249, 249],
            },
            margin: { left: 10, right: 10 },
            styles: {
              fontSize: 8,
              cellPadding: 3,
              tableWidth: "auto",
            },
            columnStyles: {
              0: { cellWidth: 55 },
              1: { cellWidth: 55 },
              2: { cellWidth: 55 },
              3: { cellWidth: 20, halign: "center" },
            },
            didParseCell: (data) => {
              if (data.column.index === 3) {
                if (data.cell.raw === "Pass") {
                  data.cell.styles.fillColor = [56, 179, 95];
                  data.cell.styles.textColor = [255, 255, 255];
                } else if (data.cell.raw === "Fail") {
                  data.cell.styles.fillColor = [231, 76, 60];
                  data.cell.styles.textColor = [255, 255, 255];
                }
              }
            },
          });

          yPosition = doc.lastAutoTable.finalY + 10;
        });
      });
    },

    filteredResultsOnlyFails() {
      return Object.entries(this.categorizedResults).reduce(
        (acc, [category, policies]) => {
          const failedPolicies = Object.entries(policies).reduce(
            (policyAcc, [policyName, results]) => {
              const failedResults = results.filter((result) => !result.Equal);
              if (failedResults.length) policyAcc[policyName] = failedResults;
              return policyAcc;
            },
            {}
          );

          if (Object.keys(failedPolicies).length)
            acc[category] = failedPolicies;
          return acc;
        },
        {}
      );
    },

    openExportModal() {
      if (
        !this.categorizedResults ||
        !Object.keys(this.categorizedResults).length
      ) {
        this.errorMessage =
          "No comparison results found. Please compare files before exporting.";
        console.warn(this.errorMessage);
        return;
      }
      this.isExportModalOpen = true;
    },

    closeExportModal() {
      this.isExportModalOpen = false;
      this.errorMessage = "";
    },

    handleExportReport({ logoFile, supportMessage, exportAllResults }) {
      this.logoFile = logoFile;
      this.supportMessage = supportMessage;
      this.exportAllResults = exportAllResults;
      this.exportToPDF({ supportMessage, logoFile, exportAllResults });
      this.closeExportModal();
    },

    openPolicyModal({ areaTitle, passingPolicies, failingPolicies }) {
      this.selectedAreaTitle = areaTitle;
      this.selectedPassingPolicies = passingPolicies || [];
      this.selectedFailingPolicies = failingPolicies || [];
      this.isPolicyModalOpen = true;
    },

    closePolicyModal() {
      this.isPolicyModalOpen = false;
      this.selectedAreaTitle = "";
      this.selectedPassingPolicies = [];
      this.selectedFailingPolicies = [];
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  background-color: #e8f0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
  margin-left: 20px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
  font-family: "Roboto", sans-serif;
}

.nav-bar a:hover {
  color: #38b35f;
}

.main-content {
  width: 100%;
  height: calc(100vh - 80px); /* Adjusted height */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Roboto", sans-serif;
}

.content-header {
  background-color: #f1f2f3;
  color: #2a2c53;
  padding: 20px;
  border-bottom: 2px solid #e0e0e0;
  font-family: "Roboto", sans-serif;
  margin: 20px -20px 0 -20px; /* Use negative margins to offset .main-content padding */
}

.dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

label {
  font-size: 1.1rem;
  color: #2a2c53;
  margin-right: 10px;
  font-family: "Roboto", sans-serif;
}

select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: "Roboto", sans-serif;
}

select:focus {
  border-color: #1e90ff;
  box-shadow: 0 0 8px rgba(30, 144, 255, 0.5);
  outline: none;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #38b35f;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

button:hover {
  background-color: #2a2c53;
  transform: translateY(-2px);
}

button:active {
  background-color: #2a2c53;
  transform: translateY(0);
}

button:not(:last-child) {
  margin-right: 10px;
}

.content {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  font-family: "Roboto", sans-serif;
}

.config-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  font-family: "Roboto", sans-serif;
}

.config {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Allow horizontal scrolling if content overflows */
  font-family: "Roboto", sans-serif;
}

.comparison-results {
  width: 100%;
  margin-top: 20px; /* Added margin for spacing */
  font-family: "Roboto", sans-serif;
}

.comparison-header {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align everything to the left */
  margin-bottom: 20px;
  gap: 15px; /* Add space between elements */
  font-family: "Roboto", sans-serif;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 45px; /* Added space between toggle and label */
  font-family: "Roboto", sans-serif;
}

.toggle-label {
  font-size: 1rem;
  color: #333;
  white-space: nowrap; /* Prevent label text from breaking into new lines */
  font-family: "Roboto", sans-serif;
}

.summary-tiles {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.tile {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

.tile-pass {
  background-color: #38b35f;
}

.tile-fail {
  background-color: #e74c3c;
}

.policy-section {
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
}

.category-section {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

h3 {
  font-size: 1.3rem;
  color: #444;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

h4 {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 8px;
  font-family: "Roboto", sans-serif;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  color: #333;
  max-height: 400px;
  overflow-y: auto;
  font-family: "Roboto", sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
  table-layout: fixed;
  margin-top: 20px; /* Added margin for spacing */
  font-family: "Roboto", sans-serif;
}

table,
th,
td {
  border: 1px solid #ddd;
  font-family: "Roboto", sans-serif;
}

th {
  background-color: #e0e0e0;
  color: #333;
  font-weight: bold;
  padding: 15px 12px;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

td {
  padding: 15px 12px;
  text-align: left;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Roboto", sans-serif;
}

th:nth-of-type(1),
td:nth-of-type(1) {
  width: 25%;
}

th:nth-of-type(2),
td:nth-of-type(2) {
  width: 25%;
}

th:nth-of-type(3),
td:nth-of-type(3) {
  width: 25%;
}

th:nth-of-type(4),
td:nth-of-type(4) {
  width: 10%;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e9e9e9;
}

td.status-pass {
  background-color: Green;
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

td.status-fail {
  background-color: Red;
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  font-family: "Roboto", sans-serif;
}

.error {
  color: red;
  font-size: 1.2rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

/* Add tile container styling */
.stats-tiles {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.category-section {
  margin-bottom: 20px;
}

.policy-section {
  margin-top: 15px;
}

.export-button {
  white-space: nowrap; /* Prevents text wrapping */
  padding: 10px 15px; /* Adjust padding to fit text */
  font-size: 1rem; /* Set font size to make text fit */
  width: auto; /* Ensures the button width adjusts based on content */
}

/* Styles for the different comparison buttons */
.btn-fetch {
  background-color: #38b35f;
}

.btn-compare-custom {
  background-color: #2a2c53;
}

.btn-compare-standard {
  background-color: #1e88e5;
}

/* Style for the standard baseline comparison note */
.baseline-note {
  margin: 15px 0;
}

.info-banner {
  display: flex;
  align-items: flex-start;
  background-color: #e3f2fd;
  border-left: 4px solid #1e88e5;
  padding: 12px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.info-icon {
  font-size: 18px;
  margin-right: 10px;
  line-height: 1;
}

.info-banner p {
  margin: 0;
  color: #0d47a1;
  font-size: 14px;
}
</style>

// standard-security-baseline.js
// This file contains standard security baseline checks that can be used for comparison across all tenants

// Define standard conditional access policy checks with their details and check functions
export const conditionalAccessChecks = {
    "MT.1001": {
        id: "MT.1001",
        title: "At least one Conditional Access policy is configured with device compliance",
        description: "Device compliance conditional access policy can be used to require devices to be compliant with the tenant's security configuration.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("compliantDevice")
            );
        }
    },
    "MT.1003": {
        id: "MT.1003",
        title: "At least one Conditional Access policy is configured with All cloud apps",
        description: "Ensure that every app has at least one Conditional Access policy applied.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.applications &&
                policy.conditions.applications.includeApplications &&
                policy.conditions.applications.includeApplications.includes("All")
            );
        }
    },
    "MT.1004": {
        id: "MT.1004",
        title: "At least one Conditional Access policy is configured with All Cloud Apps and All Users",
        description: "Ensure that every app has at least one Conditional Access policy applied and it is assigned to 'All users'.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.applications &&
                policy.conditions.applications.includeApplications &&
                policy.conditions.applications.includeApplications.includes("All") &&
                policy.conditions.users &&
                policy.conditions.users.includeUsers &&
                policy.conditions.users.includeUsers.includes("All")
            );
        }
    },
    "MT.1005": {
        id: "MT.1005",
        title: "All Conditional Access policies are configured to exclude at least one emergency account or group",
        description: "Checks if the tenant has at least one emergency/break glass account or account group excluded from all conditional access policies",
        checkFunction: (policies) => {
            // This check looks for any excluded users which might be emergency accounts
            // In a real implementation, you might want to check for specific known emergency accounts
            return policies.every(policy =>
                policy.conditions &&
                policy.conditions.users &&
                policy.conditions.users.excludeUsers &&
                policy.conditions.users.excludeUsers.length > 0
            );
        }
    },
    "MT.1006": {
        id: "MT.1006",
        title: "At least one Conditional Access policy is configured to require MFA for users with administrator roles",
        description: "Checks if the tenant has at least one Conditional policy targeting users with administrator roles.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.users &&
                policy.conditions.users.includeRoles &&
                policy.conditions.users.includeRoles.length > 0 &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("mfa")
            );
        }
    },
    "MT.1007": {
        id: "MT.1007",
        title: "At least one Conditional Access policy is configured to require MFA for all users",
        description: "Checks if the tenant has at least one conditional access policy requiring multifactor authentication for all users",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.users &&
                policy.conditions.users.includeUsers &&
                policy.conditions.users.includeUsers.includes("All") &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("mfa")
            );
        }
    },
    "MT.1008": {
        id: "MT.1008",
        title: "At least one Conditional Access policy is configured to require MFA for Azure management",
        description: "Checks if the tenant has at least one conditional access policy requiring MFA for admins.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.applications &&
                policy.conditions.applications.includeApplications &&
                policy.conditions.applications.includeApplications.some(app =>
                    app === "797f4846-ba00-4fd7-ba43-dac1f8f63013" || // Azure Management
                    app === "00000002-0000-0ff1-ce00-000000000000"    // Azure Portal
                ) &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("mfa")
            );
        }
    },
    "MT.1009": {
        id: "MT.1009",
        title: "At least one Conditional Access policy is configured to block other legacy authentication",
        description: "Checks if the tenant has at least one conditional access policy that blocks legacy authentication.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.clientAppTypes &&
                policy.conditions.clientAppTypes.includes("otherLegacyProtocols") &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("block")
            );
        }
    },
    "MT.1010": {
        id: "MT.1010",
        title: "At least one Conditional Access policy is configured to block legacy authentication for Exchange ActiveSync",
        description: "Checks if the tenant has at least one conditional access policy that blocks legacy authentication for Exchange Active Sync authentication.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.clientAppTypes &&
                policy.conditions.clientAppTypes.includes("exchangeActiveSync") &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("block")
            );
        }
    },
    "MT.1012": {
        id: "MT.1012",
        title: "At least one Conditional Access policy is configured to require MFA for risky sign-ins",
        description: "Checks if the tenant has at least one conditional access policy requiring multifactor authentication for risky sign-ins.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.signInRiskLevels &&
                policy.conditions.signInRiskLevels.length > 0 &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("mfa")
            );
        }
    },
    "MT.1013": {
        id: "MT.1013",
        title: "At least one Conditional Access policy is configured to require new password when user risk is high",
        description: "Checks if the tenant has at least one conditional access policy requiring password change for high user risk.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.userRiskLevels &&
                policy.conditions.userRiskLevels.includes("high") &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("passwordChange")
            );
        }
    },
    "MT.1015": {
        id: "MT.1015",
        title: "At least one Conditional Access policy is configured to block access for unknown or unsupported device platforms",
        description: "Checks if the tenant has at least one Conditional Access policy is configured to block access for unknown or unsupported device platforms.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.platforms &&
                policy.conditions.platforms.includePlatforms &&
                policy.conditions.platforms.includePlatforms.includes("all") &&
                policy.conditions.platforms.excludePlatforms &&
                policy.conditions.platforms.excludePlatforms.length > 0 &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("block")
            );
        }
    },
    "MT.1016": {
        id: "MT.1016",
        title: "At least one Conditional Access policy is configured to require MFA for guest access",
        description: "Checks if the tenant has at least one conditional access policy requiring multifactor authentication for all guest users.",
        checkFunction: (policies) => {
            return policies.some(policy =>
                policy.conditions &&
                policy.conditions.users &&
                policy.conditions.users.includeUsers &&
                policy.conditions.users.includeUsers.some(user =>
                    user === "GuestsOrExternalUsers" ||
                    user.toLowerCase().includes("guest")
                ) &&
                policy.grantControls &&
                policy.grantControls.builtInControls &&
                policy.grantControls.builtInControls.includes("mfa")
            );
        }
    }
};

export const azureADDirectorySettingsChecks = {
    "AD.2001": {
        id: "AD.2001",
        title: "M365 Groups should have guest access restricted",
        description: "Microsoft 365 Groups should restrict guest access for better security and data protection.",
        checkFunction: (settings) => {
            // Looking for Group.Unified settings
            const unifiedGroupSettings = settings.find(setting =>
                setting.displayName === "Group.Unified" ||
                setting.templateId === "62375ab9-6b52-47ed-826b-58e47e0e304b"
            );

            if (!unifiedGroupSettings) return false;

            // Find the AllowToAddGuests value
            const allowToAddGuests = unifiedGroupSettings.values.find(v =>
                v.name === "AllowToAddGuests"
            );

            return allowToAddGuests && allowToAddGuests.value.toLowerCase() === "false";
        }
    },
    "AD.2002": {
        id: "AD.2002",
        title: "Microsoft 365 Group creation should be restricted",
        description: "Microsoft 365 Group creation should be restricted to specific users or groups for better governance.",
        checkFunction: (settings) => {
            // Looking for Group.Unified settings
            const unifiedGroupSettings = settings.find(setting =>
                setting.displayName === "Group.Unified" ||
                setting.templateId === "62375ab9-6b52-47ed-826b-58e47e0e304b"
            );

            if (!unifiedGroupSettings) return false;

            // Find the EnableGroupCreation value
            const enableGroupCreation = unifiedGroupSettings.values.find(v =>
                v.name === "EnableGroupCreation"
            );

            return enableGroupCreation && enableGroupCreation.value.toLowerCase() === "false";
        }
    },
    "AD.2003": {
        id: "AD.2003",
        title: "User consent for risky apps should be blocked",
        description: "Users should not be allowed to consent to risky applications, which helps prevent potential security risks.",
        checkFunction: (settings) => {
            // Looking for Consent Policy Settings
            const consentPolicySettings = settings.find(setting =>
                setting.displayName === "Consent Policy Settings" ||
                setting.templateId === "dffd5d46-495d-40a9-8e21-954ff55e198a"
            );

            if (!consentPolicySettings) return false;

            // Find the BlockUserConsentForRiskyApps value
            const blockUserConsent = consentPolicySettings.values.find(v =>
                v.name === "BlockUserConsentForRiskyApps"
            );

            return blockUserConsent && blockUserConsent.value.toLowerCase() === "true";
        }
    },
    "AD.2004": {
        id: "AD.2004",
        title: "Admin consent requests should be enabled",
        description: "Enabling admin consent requests allows users to request access to applications that require admin consent, improving the user experience while maintaining security.",
        checkFunction: (settings) => {
            // Looking for Consent Policy Settings
            const consentPolicySettings = settings.find(setting =>
                setting.displayName === "Consent Policy Settings" ||
                setting.templateId === "dffd5d46-495d-40a9-8e21-954ff55e198a"
            );

            if (!consentPolicySettings) return false;

            // Find the EnableAdminConsentRequests value
            const enableAdminConsentRequests = consentPolicySettings.values.find(v =>
                v.name === "EnableAdminConsentRequests"
            );

            return enableAdminConsentRequests && enableAdminConsentRequests.value.toLowerCase() === "true";
        }
    },
    "AD.2005": {
        id: "AD.2005",
        title: "Password banned lists should be enabled on-premises",
        description: "Enabling banned password lists on-premises helps prevent users from using common and easily guessable passwords.",
        checkFunction: (settings) => {
            // Looking for Password Rule Settings
            const passwordRuleSettings = settings.find(setting =>
                setting.displayName === "Password Rule Settings" ||
                setting.templateId === "5cf42378-d67d-4f36-ba46-e8b86229381d"
            );

            if (!passwordRuleSettings) return false;

            // Find the EnableBannedPasswordCheckOnPremises value
            const enableBannedPasswordCheck = passwordRuleSettings.values.find(v =>
                v.name === "EnableBannedPasswordCheckOnPremises"
            );

            return enableBannedPasswordCheck && enableBannedPasswordCheck.value.toLowerCase() === "true";
        }
    },
    "AD.2006": {
        id: "AD.2006",
        title: "Authorization policy should block user consent for risky apps",
        description: "Blocking user consent for risky apps in the authorization policy helps prevent potential security breaches from unauthorized application access.",
        checkFunction: (settings) => {
            // This may be in a separate authorization policy object
            const authPolicy = settings.find(setting =>
                setting.displayName === "Authorization Policy"
            );

            if (!authPolicy) return false;

            return authPolicy.allowUserConsentForRiskyApps === false;
        }
    }
};

export const deviceRegistrationPolicyChecks = {
    "DEV.1001": {
        id: "DEV.1001",
        title: "Local admin password policy should be enabled",
        description: "The local admin password policy should be enabled to ensure secure local administrator accounts on Azure AD joined devices.",
        checkFunction: (policy) => {
            return policy && policy.localAdminPassword && policy.localAdminPassword.isEnabled === true;
        }
    },
    "DEV.1002": {
        id: "DEV.1002",
        title: "User device quota should be appropriately limited",
        description: "User device quota should be limited to prevent users from registering an excessive number of devices.",
        checkFunction: (policy) => {
            return policy && policy.userDeviceQuota !== null && policy.userDeviceQuota <= 10;
        }
    },
    "DEV.1003": {
        id: "DEV.1003",
        title: "Azure AD Join local admins policy should be configured securely",
        description: "Azure AD Join local admins policy should not automatically grant global admins local admin rights on joined devices.",
        checkFunction: (policy) => {
            return policy &&
                policy.azureADJoin &&
                policy.azureADJoin.localAdmins &&
                policy.azureADJoin.localAdmins.enableGlobalAdmins === false;
        }
    },
    "DEV.1004": {
        id: "DEV.1004",
        title: "Azure AD Join should be admin configurable",
        description: "Azure AD Join should be admin configurable to ensure proper control over device joining scenarios.",
        checkFunction: (policy) => {
            return policy &&
                policy.azureADJoin &&
                policy.azureADJoin.isAdminConfigurable === true;
        }
    }
};

export const defenderOffice365Checks = {
    "DEF.1001": {
        id: "DEF.1001",
        title: "ATP for SharePoint, OneDrive, and Teams should be enabled",
        description: "Advanced Threat Protection should be enabled for SharePoint, OneDrive, and Teams to protect against malicious files.",
        checkFunction: (policy) => {
            return policy && policy.EnableATPForSPOTeamsODB === true;
        }
    },
    "DEF.1002": {
        id: "DEF.1002",
        title: "Safe Documents should be enabled",
        description: "Safe Documents provides an additional layer of security by scanning documents opened in Protected View in Office applications.",
        checkFunction: (policy) => {
            return policy && policy.EnableSafeDocs === true;
        }
    },
    "DEF.1003": {
        id: "DEF.1003",
        title: "Users should not be allowed to open files in Safe Documents",
        description: "Users should not be allowed to open files that have not been scanned by Safe Documents, even if they appear safe.",
        checkFunction: (policy) => {
            return policy && policy.AllowSafeDocsOpen === false;
        }
    }
};

export const exchangeSecurityChecks = {
    // Admin Audit Log Settings
    "EXC.1001": {
        id: "EXC.1001",
        title: "Admin Audit Logging should be enabled",
        description: "Admin audit logging captures Exchange administrator actions, which is essential for security monitoring and compliance.",
        checkFunction: (settings) => {
            const adminAuditLog = settings.find(setting =>
                setting.Name === "Admin Audit Log Settings" ||
                setting.Identity === "Admin Audit Log Settings"
            );
            return adminAuditLog && adminAuditLog.AdminAuditLogEnabled === true;
        }
    },
    "EXC.1002": {
        id: "EXC.1002",
        title: "Unified Audit Log ingestion should be enabled",
        description: "Unified Audit Log ingestion should be enabled to ensure actions are recorded in the Microsoft 365 audit log.",
        checkFunction: (settings) => {
            const adminAuditLog = settings.find(setting =>
                setting.Name === "Admin Audit Log Settings" ||
                setting.Identity === "Admin Audit Log Settings"
            );
            return adminAuditLog && adminAuditLog.UnifiedAuditLogIngestionEnabled === true;
        }
    },

    // Anti-Phish Policy
    "EXC.1003": {
        id: "EXC.1003",
        title: "Anti-phishing policy should be enabled",
        description: "Anti-phishing policy should be enabled to protect users from phishing attacks.",
        checkFunction: (settings) => {
            const antiPhishPolicy = settings.find(setting =>
                setting.Name && setting.Name.includes("AntiPhish") ||
                setting.Identity && setting.Identity.includes("AntiPhish")
            );
            return antiPhishPolicy && antiPhishPolicy.Enabled === true;
        }
    },
    "EXC.1004": {
        id: "EXC.1004",
        title: "Mailbox Intelligence should be enabled in anti-phishing policy",
        description: "Mailbox Intelligence uses artificial intelligence to determine if an email is a phishing attempt based on the user's communication patterns.",
        checkFunction: (settings) => {
            const antiPhishPolicy = settings.find(setting =>
                setting.Name && setting.Name.includes("AntiPhish") ||
                setting.Identity && setting.Identity.includes("AntiPhish")
            );
            return antiPhishPolicy && antiPhishPolicy.EnableMailboxIntelligence === true;
        }
    },
    "EXC.1005": {
        id: "EXC.1005",
        title: "Spoof Intelligence should be enabled in anti-phishing policy",
        description: "Spoof Intelligence should be enabled to protect against spoofing attacks.",
        checkFunction: (settings) => {
            const antiPhishPolicy = settings.find(setting =>
                setting.Name && setting.Name.includes("AntiPhish") ||
                setting.Identity && setting.Identity.includes("AntiPhish")
            );
            return antiPhishPolicy && antiPhishPolicy.EnableSpoofIntelligence === true;
        }
    },
    "EXC.1006": {
        id: "EXC.1006",
        title: "DMARC policy enforcement should be enabled",
        description: "DMARC policy enforcement helps protect against email spoofing and phishing attacks.",
        checkFunction: (settings) => {
            const antiPhishPolicy = settings.find(setting =>
                setting.Name && setting.Name.includes("AntiPhish") ||
                setting.Identity && setting.Identity.includes("AntiPhish")
            );
            return antiPhishPolicy && antiPhishPolicy.HonorDmarcPolicy === true;
        }
    },

    // Malware Filter Policy
    "EXC.1007": {
        id: "EXC.1007",
        title: "Malware filter should have file filtering enabled",
        description: "File filtering in the malware filter policy helps block potentially malicious file types.",
        checkFunction: (settings) => {
            const malwareFilterPolicy = settings.find(setting =>
                setting.ObjectClass &&
                setting.ObjectClass.includes("msExchMalwareFilterConfig")
            );
            return malwareFilterPolicy && malwareFilterPolicy.EnableFileFilter === true;
        }
    },
    "EXC.1008": {
        id: "EXC.1008",
        title: "ZAP (Zero-hour Auto Purge) should be enabled",
        description: "ZAP should be enabled to remove malicious messages that have already been delivered to mailboxes.",
        checkFunction: (settings) => {
            const malwareFilterPolicy = settings.find(setting =>
                setting.ObjectClass &&
                setting.ObjectClass.includes("msExchMalwareFilterConfig")
            );
            return malwareFilterPolicy && malwareFilterPolicy.ZapEnabled === true;
        }
    },

    // OWA Mailbox Policy
    "EXC.1009": {
        id: "EXC.1009",
        title: "OWA mailbox policy should block potentially dangerous file types",
        description: "OWA mailbox policy should block potentially dangerous file types to reduce the risk of malware.",
        checkFunction: (settings) => {
            const owaPolicy = settings.find(setting =>
                setting.Identity && setting.Identity.includes("OwaMailboxPolicy")
            );

            // Check if the policy has a non-empty BlockedFileTypes list
            return owaPolicy &&
                owaPolicy.BlockedFileTypes &&
                owaPolicy.BlockedFileTypes.length > 0 &&
                // Check for key risky file extensions
                owaPolicy.BlockedFileTypes.includes(".exe") &&
                owaPolicy.BlockedFileTypes.includes(".js") &&
                owaPolicy.BlockedFileTypes.includes(".vbs");
        }
    },

    // Remote Domains
    "EXC.1010": {
        id: "EXC.1010",
        title: "Auto-forwarding to remote domains should be disabled",
        description: "Auto-forwarding to remote domains should be disabled to prevent data exfiltration.",
        checkFunction: (settings) => {
            const remoteDomain = settings.find(setting =>
                setting.Identity === "Default" &&
                setting.ObjectClass &&
                setting.ObjectClass.includes("msExchDomainContentConfig")
            );
            return remoteDomain && remoteDomain.AutoForwardEnabled === false;
        }
    },

    // Sharing Policy
    "EXC.1011": {
        id: "EXC.1011",
        title: "Default sharing policy should be disabled",
        description: "The default sharing policy should be disabled to prevent unintended sharing of calendar information.",
        checkFunction: (settings) => {
            const sharingPolicy = settings.find(setting =>
                setting.Identity === "Default Sharing Policy" ||
                (setting.Identity && setting.Identity.includes("Sharing Policy") && setting.Default === true)
            );
            return sharingPolicy && sharingPolicy.Enabled === false;
        }
    },

    // Spam Filter Policy
    "EXC.1012": {
        id: "EXC.1012",
        title: "Auto-forwarding mode should be restricted",
        description: "Auto-forwarding mode should be set to 'Off' or 'Internal' to prevent data exfiltration via email forwarding.",
        checkFunction: (settings) => {
            const spamFilterPolicy = settings.find(setting =>
                setting.Identity === "Default" &&
                setting.ObjectClass &&
                setting.ObjectClass.includes("msExchHygieneConfiguration")
            );
            return spamFilterPolicy &&
                (spamFilterPolicy.AutoForwardingMode === "Off" ||
                    spamFilterPolicy.AutoForwardingMode === "Internal");
        }
    }
};